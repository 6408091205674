import React, { useState, useEffect } from "react";
import api from "../../services/api";
import { login } from "../../services/auth";
import { Collapse } from "bootstrap";
import { useHistory } from "react-router-dom";
import Preloader from "../../components/Preloader";

const Login = (props) => {
    const history = useHistory();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [collapseErrorLogin, setCollapseErrorLogin] = useState(null);
    const [collapseSuccessLogin, setCollapseSuccessLogin] = useState(null);
    const [loader, setLoader] = useState(false);
    const [timeoutRedirect, setTimeoutRedirect] = useState(null);

    useEffect(() => {
        const collapseWarningLogin = document.getElementById("menu-warning");
        const bsCollapse = new Collapse(collapseWarningLogin, {
            toggle: false,
        });
        setCollapseErrorLogin(bsCollapse);
        const collapseSuccessLogin = document.getElementById("menu-success");
        const bsCollapseSuccess = new Collapse(collapseSuccessLogin, {
            toggle: false,
        });
        setCollapseSuccessLogin(bsCollapseSuccess);
    }, []);

    const handleFormLogin = async (e) => {
        e.preventDefault();
        try {
            setLoader(true);
            const formData = new FormData();
            formData.append("email", email);
            formData.append("password", password);
            const responseLogin = await api.post("/auth/login/", formData);
            if (responseLogin.status === 200) {
                login(responseLogin.data.key);
                collapseSuccessLogin.show();
                setTimeoutRedirect(
                    setTimeout(() => {
                        history.push("/");
                    }, 5000)
                );
            } else {
                collapseErrorLogin.show();
            }
            setLoader(false);
        } catch {
            setLoader(false);
            collapseErrorLogin.show();
        }
    };

    const redirectManually = () => {
        clearTimeout(timeoutRedirect);
        history.push("/");
    };

    return (
        <>
            <Preloader />
            <div id="page">
                <div className="page-content header-clear-medium">
                    <div className="card card-style">
                        <div className="content">
                            <h1 className="text-center font-800 font-30 mb-2">
                                Fazer Login
                            </h1>
                            <p className="text-center font-13 mt-n2 mb-3">
                                Digite seus dados
                            </p>
                            <div className="form-custom form-label form-icon mb-3">
                                <i className="bi bi-person-circle font-14"></i>
                                <input
                                    type="email"
                                    className="form-control rounded-xs"
                                    id="c1"
                                    placeholder="Seu e-mail"
                                    value={email}
                                    onChange={(event) => {
                                        setEmail(event.target.value);
                                    }}
                                />
                                <label
                                    htmlFor="c1"
                                    className={`color-theme ${
                                        email !== "" ? "form-label-active" : ""
                                    }`}
                                >
                                    Seu e-mail
                                </label>
                                <span>(obrigatório)</span>
                            </div>
                            <div className="form-custom form-label form-icon mb-3">
                                <i className="bi bi-asterisk font-12"></i>
                                <input
                                    type="password"
                                    className="form-control rounded-xs"
                                    id="c2"
                                    placeholder="Senha"
                                    value={password}
                                    onChange={(event) => {
                                        setPassword(event.target.value);
                                    }}
                                />
                                <label
                                    htmlFor="c2"
                                    className={`color-theme ${
                                        password !== ""
                                            ? "form-label-active"
                                            : ""
                                    }`}
                                >
                                    Senha
                                </label>
                                <span>(obrigatório)</span>
                            </div>
                            {loader ? (
                                <div className="d-flex justify-content-center">
                                    <div
                                        className="spinner-border color-red-dark"
                                        role="status"
                                    ></div>
                                </div>
                            ) : (
                                <a
                                    className="btn rounded-sm btn-m gradient-green text-uppercase font-700 mt-4 mb-3 btn-full shadow-bg shadow-bg-s"
                                    href=""
                                    onClick={(e) => handleFormLogin(e)}
                                >
                                    Entrar
                                </a>
                            )}
                            {/* <div className="d-flex">
                                <div>
                                    <a
                                        href="page-forgot-1.html"
                                        className="color-theme opacity-30 font-12"
                                    >
                                        Recuperar Senha
                                    </a>
                                </div>
                                <div className="ms-auto">
                                    <a
                                        href="page-register-1.html"
                                        className="color-theme opacity-30 font-12"
                                    >
                                        Registrar Conta
                                    </a>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="offcanvas offcanvas-modal rounded-m offcanvas-detached bg-theme"
                style={{
                    width: "340px",
                }}
                id="menu-warning"
            >
                <div className="gradient-red px-3 py-3">
                    <div className="d-flex mt-1">
                        <div
                            className="align-self-center"
                            onClick={() => {
                                collapseErrorLogin.hide();
                            }}
                        >
                            <i className="bi bi-x-circle-fill font-22 pe-2 scale-box color-white"></i>
                        </div>
                        <div className="align-self-center">
                            <h1 className="font-800 color-white mb-0">Erro</h1>
                        </div>
                    </div>
                    <p className="color-white opacity-60 pt-2">
                        Parece que alguns dos seus dados não conferem. Verifique
                        seus dados e tente novamente.
                    </p>
                    <a
                        className="default-link btn btn-full btn-s bg-white color-black"
                        onClick={(e) => {
                            collapseErrorLogin.hide();
                        }}
                    >
                        Digitar os dados e Tentar novamente...
                    </a>
                </div>
            </div>
            <div
                className="offcanvas offcanvas-modal rounded-m offcanvas-detached bg-theme"
                style={{
                    width: "340px",
                }}
                id="menu-success"
            >
                <div className="gradient-green px-3 py-3">
                    <div className="d-flex mt-1">
                        <div
                            className="align-self-center"
                            onClick={() => {
                                collapseSuccessLogin.hide();
                            }}
                        >
                            <i className="bi bi-check-circle-fill font-22 pe-2 scale-box color-white"></i>
                        </div>
                        <div className="align-self-center">
                            <h1 className="font-700 color-white mb-0">
                                Sucesso
                            </h1>
                        </div>
                    </div>
                    <p className="color-white opacity-90 pt-2">
                        Seu login foi feito com sucesso, iremos te redirecionar
                        para a página principal!
                    </p>
                    <a
                        href="#"
                        className="default-link btn btn-full btn-s bg-white color-black"
                        onClick={() => {
                            collapseSuccessLogin.hide();
                            redirectManually();
                        }}
                    >
                        Redirecionar manualmente
                    </a>
                </div>
            </div>
        </>
    );
};

export default Login;
