import Dexie from "dexie";

const db = new Dexie("PesquisaBrasil");
db.version(1).stores({
    surveys: "id,name,description,active,background_photo",
    questions:
        "id,surveyId,label,type_field,choices,help_text,required,key,ordering",
    userAnswers: "++id,surveyId,name,email,cpf,phone,sentToServer",
    answers: "++id,userAnswerId,questionId,value",
});

export default db;
