import React, { useState, useEffect, useCallback } from "react";
import { Collapse } from "bootstrap";
import { useHistory, Link } from "react-router-dom";

import { logout } from "../../services/auth";

export const MenuMain = () => {
    const history = useHistory();
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [classConnectionStatusOnline, setClassConnectionStatusOnline] =
        useState("");
    const [classConnectionStatusOffline, setClassConnectionStatusOffline] =
        useState("");

    // const [collapseMenu, setCollapseMenu] = useState(null);
    const pwaName = "PesquisaBrasilGazin";

    function darkMode() {
        var toggleDark = document.querySelectorAll("[data-toggle-theme]");
        function activateDarkMode() {
            document
                .getElementById("theme-check")
                .setAttribute("content", "#1f1f1f");
            document.body.classList.add("theme-dark");
            document.body.classList.remove("theme-light", "detect-theme");
            for (let i = 0; i < toggleDark.length; i++) {
                toggleDark[i].checked = "checked";
            }
            localStorage.setItem(pwaName + "-Theme", "dark-mode");
            console.log("dark");
        }
        function activateLightMode() {
            document
                .getElementById("theme-check")
                .setAttribute("content", "#FFFFFF");
            document.body.classList.add("theme-light");
            document.body.classList.remove("theme-dark", "detect-theme");
            for (let i = 0; i < toggleDark.length; i++) {
                toggleDark[i].checked = false;
            }
            localStorage.setItem(pwaName + "-Theme", "light-mode");
            console.log("light");
        }

        function setColorScheme() {
            const isDarkMode = window.matchMedia(
                "(prefers-color-scheme: dark)"
            ).matches;
            const isLightMode = window.matchMedia(
                "(prefers-color-scheme: light)"
            ).matches;
            const isNoPreference = window.matchMedia(
                "(prefers-color-scheme: no-preference)"
            ).matches;
            window
                .matchMedia("(prefers-color-scheme: dark)")
                .addListener((e) => e.matches && activateDarkMode());
            window
                .matchMedia("(prefers-color-scheme: light)")
                .addListener((e) => e.matches && activateLightMode());
            if (isDarkMode) activateDarkMode();
            if (isLightMode) activateLightMode();
        }

        //Activating Dark Mode
        var darkModeSwitch = document.querySelectorAll("[data-toggle-theme]");
        darkModeSwitch.forEach((el) =>
            el.addEventListener("click", (e) => {
                if (document.body.className == "theme-light") {
                    removeTransitions();
                    activateDarkMode();
                } else if (document.body.className == "theme-dark") {
                    removeTransitions();
                    activateLightMode();
                }
                setTimeout(function () {
                    addTransitions();
                }, 350);
            })
        );

        //Set Color Based on Remembered Preference.
        if (localStorage.getItem(pwaName + "-Theme") == "dark-mode") {
            for (let i = 0; i < toggleDark.length; i++) {
                toggleDark[i].checked = "checked";
            }
            document.body.className = "theme-dark";
        }
        if (localStorage.getItem(pwaName + "-Theme") == "light-mode") {
            document.body.className = "theme-light";
        }
        if (document.body.className == "detect-theme") {
            setColorScheme();
        }

        //Detect Dark/Light Mode
        const darkModeDetect = document.querySelectorAll(".detect-dark-mode");
        darkModeDetect.forEach((el) =>
            el.addEventListener("click", (e) => {
                document.body.classList.remove("theme-light", "theme-dark");
                document.body.classList.add("detect-theme");
                setTimeout(function () {
                    setColorScheme();
                }, 50);
            })
        );

        function removeTransitions() {
            var falseTransitions = document.querySelectorAll(
                ".btn, .header, #footer-bar, .menu-box, .menu-active"
            );
            for (let i = 0; i < falseTransitions.length; i++) {
                falseTransitions[i].style.transition = "all 0s ease";
            }
        }
        function addTransitions() {
            var trueTransitions = document.querySelectorAll(
                ".btn, .header, #footer-bar, .menu-box, .menu-active"
            );
            for (let i = 0; i < trueTransitions.length; i++) {
                trueTransitions[i].style.transition = "";
            }
        }
    }

    useEffect(() => {
        // const collapseMenu = document.getElementById("menu-main");
        // const bsCollapse = new Collapse(collapseMenu, {
        //     toggle: false,
        // });
        // setCollapseMenu(bsCollapse);
        console.log("render");
        return () => darkMode();
    }, []);

    useEffect(() => {
        // Update network status
        const handleStatusChange = () => {
            setIsOnline(navigator.onLine);
            if (navigator.onLine) {
                setClassConnectionStatusOnline("online-message-active");
            } else {
                setClassConnectionStatusOffline("offline-message-active");
            }
            setTimeout(() => {
                setClassConnectionStatusOnline("");
                setClassConnectionStatusOffline("");
            }, 4000);
        };

        // Listen to the online status
        window.addEventListener("online", handleStatusChange);

        // Listen to the offline status
        window.addEventListener("offline", handleStatusChange);

        // Specify how to clean up after this effect for performance improvment
        return () => {
            window.removeEventListener("online", handleStatusChange);
            window.removeEventListener("offline", handleStatusChange);
        };
    }, [isOnline]);

    return (
        <>
            <p
                className={`offline-message bg-red-dark shadow-bg shadow-bg-s color-white ${classConnectionStatusOffline}`}
            >
                <i className="bi bi-wifi-off pe-2"></i> Sem conexão com a
                internet.
            </p>
            <p
                className={`online-message bg-green-dark shadow-bg shadow-bg-s color-white ${classConnectionStatusOnline}`}
            >
                <i className="bi bi-wifi pe-2"></i> Você está online novamente.
            </p>
            <div className="header-bar header-fixed header-app header-bar-detached">
                <a
                    data-bs-toggle="offcanvas"
                    data-bs-target="#menu-main"
                    href="#"
                    // onClick={() => collapseMenu.show()}
                >
                    <i className="bi bi-list color-theme"></i>
                </a>
                <a href="#" className="header-title color-theme">
                    Pesquisa Brasil Gazin
                </a>
                {/* <a
                    href="#"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#menu-color"
                >
                    <i className="bi bi-palette-fill font-13 color-highlight"></i>
                </a>
                <a
                    href="#"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#menu-bell"
                >
                    <em className="badge bg-highlight ms-1">3</em>
                    <i className="font-14 bi bi-bell-fill"></i>
                </a>*/}
                <a href="#" className="show-on-theme-light" data-toggle-theme>
                    <i className="bi bi-moon-fill font-13"></i>
                </a>
                <a href="#" className="show-on-theme-dark" data-toggle-theme>
                    <i className="bi bi-lightbulb-fill color-yellow-dark font-13"></i>
                </a>
            </div>
            <div
                id="menu-main"
                data-menu-active="nav-homes"
                style={{
                    width: "280px",
                }}
                className="offcanvas offcanvas-start offcanvas-detached rounded-m"
            >
                <div
                    className="card card-style bg-23 mb-3 rounded-m mt-3"
                    data-card-height="150"
                >
                    <div className="card-top m-3">
                        <a
                            href="#"
                            data-bs-dismiss="offcanvas"
                            className="icon icon-xs bg-theme rounded-s color-theme float-end"
                        >
                            <i className="bi bi-caret-left-fill"></i>
                        </a>
                    </div>
                    <div className="card-bottom p-3">
                        <h1 className="color-white font-20 font-700 mb-n2">
                            Pesquisa Brasil
                        </h1>
                        <p className="color-white font-12 opacity-70 mb-n1">
                            Pesquisa Brasil Gazin
                        </p>
                    </div>
                    <div className="card-overlay bg-gradient-fade rounded-0"></div>
                </div>

                <div className="bg-theme mx-3 rounded-m shadow-m mt-3 mb-3">
                    <div className="d-flex px-2 pb-2 pt-2">
                        {/* <div>
                            <a href="#">
                                <img
                                    src="https://api.placid.app/u/qsraj?title[text]=Pesquisa%20Brasil"
                                    width="45"
                                    className="rounded-s"
                                    alt="img"
                                />
                            </a>
                        </div> */}
                        <div className="ps-2 align-self-center">
                            <h5 className="ps-1 mb-0 line-height-xs pt-1">
                                Pesquisa Brasil
                            </h5>
                            <h6 className="ps-1 mb-0 font-400 opacity-40">
                                App de pesquisas da Gazin
                            </h6>
                        </div>
                        <div className="ms-auto">
                            <a
                                href="#"
                                data-bs-toggle="dropdown"
                                className="icon icon-m ps-3"
                            >
                                <i className="bi bi-three-dots-vertical font-18 color-theme"></i>
                            </a>
                            <div className="dropdown-menu  bg-transparent border-0 mt-n1 ms-3">
                                <div className="card card-style rounded-m shadow-xl mt-1 me-1">
                                    <div className="list-group list-custom list-group-s list-group-flush rounded-xs px-3 py-1">
                                        {/* <a
                                            href="page-profile-admin.html"
                                            className="color-theme opacity-70 list-group-item py-1"
                                        >
                                            <strong className="font-500 font-12">
                                                Your Profile
                                            </strong>
                                            <i className="bi bi-chevron-right"></i>
                                        </a>
                                        <a
                                            href="page-activity.html"
                                            className="color-theme opacity-70 list-group-item py-1"
                                        >
                                            <strong className="font-500 font-12">
                                                Notifications
                                            </strong>
                                            <i className="bi bi-chevron-right"></i>
                                        </a> */}
                                        <a
                                            href="page-login-2.html"
                                            className="color-theme opacity-70 list-group-item py-1"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                logout();
                                                history.push("/login");
                                            }}
                                        >
                                            <strong className="font-500 font-12">
                                                Sair
                                            </strong>
                                            <i className="bi bi-chevron-right"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <span className="menu-divider">Navegação</span>
                <div className="menu-list">
                    <div className="card card-style rounded-m p-3 py-2 mb-0">
                        <Link to="/" id="nav-homes">
                            <i className="gradient-blue shadow-bg shadow-bg-xs bi bi-house-fill"></i>
                            <span>Homepage</span>
                            <i className="bi bi-chevron-right"></i>
                        </Link>
                        <Link to="/" id="nav-homes">
                            <i className="gradient-red shadow-bg shadow-bg-xs bi bi-star-fill"></i>
                            <span>Pesquisas</span>
                            <i className="bi bi-chevron-right"></i>
                        </Link>
                        <Link to="/" id="nav-homes">
                            <i className="gradient-green shadow-bg shadow-bg-xs bi bi-bell-fill"></i>
                            <span>Notificações</span>
                            <i className="bi bi-chevron-right"></i>
                        </Link>
                    </div>
                </div>

                <span className="menu-divider mt-4">CONFIGURAÇÕES</span>
                <div className="menu-list">
                    <div className="card card-style rounded-m p-3 py-2 mb-0">
                        {/* <a href="#">
                            <i className="gradient-highlight shadow-bg shadow-bg-xs bi bi-palette-fill"></i>
                            <span>Highlights</span>
                            <i className="bi bi-chevron-right"></i>
                        </a> */}
                        <a
                            href="#"
                            data-toggle-theme
                            data-trigger-switch="switch-1"
                        >
                            <i className="gradient-dark shadow-bg shadow-bg-xs bi bi-moon-fill font-13"></i>
                            <span>Tema Escuro</span>
                            <div className="form-switch ios-switch switch-green switch-s me-2">
                                <input
                                    type="checkbox"
                                    data-toggle-theme
                                    className="ios-input"
                                    id="switch-1"
                                />
                                <label
                                    className="custom-control-label"
                                    htmlFor="switch-1"
                                ></label>
                            </div>
                        </a>
                    </div>
                </div>

                {/* <p className="text-center mb-0 mt-n3 pb-3 font-9 text-uppercase font-600 color-theme">
                    Feito por Fast Mídia{" "}
                    <i className=" font-9 px-1 bi bi-heart-fill color-red-dark"></i>{" "}
                    by Enabled in <span className="copyright-year"></span>.
                </p> */}
            </div>
        </>
    );
};
