import React from "react";
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect,
} from "react-router-dom";
import { isAuthenticated } from "./services/auth";
// import history from "./services/history";

import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Survey from "./pages/Survey";

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props) => {
            if (isAuthenticated()) {
                return <Component {...props} />;
            } else {
                return (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: props.location },
                        }}
                    />
                );
            }
        }}
    />
);

const Routes = () => (
    <Router>
        <Switch>
            <PrivateRoute exact path="/" component={Dashboard} />
            <PrivateRoute exact path="/pesquisa/:id" component={Survey} />
            <Route exact path="/login" component={Login} />
        </Switch>
    </Router>
);

export default Routes;
