import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import db from "../../services/db";
import Preloader from "../../components/Preloader";
import { MenuMain } from "../../components/MenuMain";
import FooterMenu from "../../components/FooterMenu";
import { PWA } from "../../components/PWA";
import { cpfMask, validarCPF } from "../../services/cpf";
import { phoneMask } from "../../services/phone";
import { Toast, Collapse } from "bootstrap";
import { useHistory } from "react-router-dom";
import api, { URL_API } from "../../services/api";
import { getToken } from "../../services/auth";

const Survey = (props) => {
    const history = useHistory();
    const [survey, setSurvey] = useState({});
    const [questions, setQuestions] = useState([]);
    const [questionsAnswers, setQuestionsAnswers] = useState({
        client_cpf: "",
        client_name: "",
        client_email: "",
        client_phone: "",
    });
    const [toast, setToast] = useState(null);
    const [totalRequiredFields, setTotalRequiredFields] = useState(0);
    const [totalRequiredFieldsCompleted, setTotalRequiredFieldsCompleted] =
        useState({});
    const [messageErrorTitle, setMessageErrorTitle] = useState("Erro");
    const [messageErrorDescription, setMessageErrorDescription] =
        useState("Erro");
    const [loader, setLoader] = useState(true);
    const [loaderSubmit, setLoaderSubmit] = useState(false);
    const [collapseSuccess, setCollapseSuccess] = useState(null);

    useEffect(() => {
        const load = async () => {
            const surveyId = Number(props.match.params.id);
            const surveyDb = await db.surveys.get(surveyId);
            const surveyQuestionsDb = await db.questions
                .where("surveyId")
                .equals(surveyId)
                .toArray();
            setSurvey(surveyDb);
            setQuestions(surveyQuestionsDb);
            let requiredFields = 0;
            for (const question of surveyQuestionsDb) {
                if (question.required) {
                    requiredFields++;
                }
            }
            setTotalRequiredFields(requiredFields);
            const notificationToast = document.getElementById(
                "notification-bar-choice-required"
            );
            const notificationToastBootstrap = new Toast(notificationToast);
            setToast(notificationToastBootstrap);
            const collapseSuccess = document.getElementById("menu-success");
            const bsCollapseSuccess = new Collapse(collapseSuccess, {
                toggle: false,
            });
            setCollapseSuccess(bsCollapseSuccess);
            setLoader(false);
        };
        load();
    }, [props.match.params.id]);

    const renderQuestionChoices = (question) => {
        const inputName = `question-${question.key}-${question.id}`;
        switch (question.type_field) {
            case "RA":
                return question.choices
                    .split(",")
                    .map((choice, choiceIndex) => (
                        <div
                            className="form-check form-check-custom mb-4"
                            key={choiceIndex}
                        >
                            <input
                                className="form-check-input"
                                type="radio"
                                value={choice}
                                id={`${inputName}-id-${choiceIndex}`}
                                name={inputName}
                                required={question.required}
                                onChange={handleInputChange}
                                data-question-id={question.id}
                                data-question-key={question.key}
                            />
                            <label
                                className="form-check-label font-24"
                                htmlFor={`${inputName}-id-${choiceIndex}`}
                            >
                                {choice}
                            </label>
                            <i className="is-checked color-green-dark bi bi-check-circle-fill font-20"></i>
                            <i className="is-unchecked color-highlight bi bi-circle-fill font-20"></i>
                        </div>
                    ));
            case "MS":
                return question.choices
                    .split(",")
                    .map((choice, choiceIndex) => (
                        <div
                            className="form-check form-check-custom"
                            key={choiceIndex}
                        >
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id={`${inputName}-id-${choiceIndex}`}
                                name={inputName}
                                // required={question.required}
                                onChange={handleInputChange}
                                data-question-id={question.id}
                                data-question-key={question.key}
                                value={choice}
                            />
                            <label
                                className="form-check-label font-24 mb-2"
                                htmlFor={`${inputName}-id-${choiceIndex}`}
                            >
                                {choice}
                            </label>
                            <i className="is-checked color-green-dark bi bi-check-square font-20"></i>
                            <i className="is-unchecked color-highlight bi bi-square font-20"></i>
                        </div>
                    ));
            case "TE":
                return (
                    <div className="form-custom form-label form-icon mb-3">
                        <i className="bi bi-pencil-fill font-12"></i>
                        <textarea
                            className="form-control rounded-xs"
                            placeholder={question.label}
                            id={`${inputName}-id`}
                            name={inputName}
                            onChange={handleInputChange}
                            data-question-id={question.id}
                            data-question-key={question.key}
                        ></textarea>
                        <label
                            htmlFor={`${inputName}-id`}
                            className="color-theme"
                        >
                            {question.label}
                        </label>
                    </div>
                );
            default:
                return null;
        }
    };

    const handleInputChange = async (event) => {
        const { target } = event;
        const { value } = target;
        const questionDb = await db.questions.get(
            Number(target.getAttribute("data-question-id"))
        );
        if (questionDb) {
            if (questionDb.required) {
                if (value === "") {
                    const copy = { ...totalRequiredFieldsCompleted };
                    delete copy[target.name];
                    setTotalRequiredFieldsCompleted(copy);
                } else {
                    if (
                        questionsAnswers[target.name] &&
                        questionsAnswers[target.name].indexOf(value) !== -1
                    ) {
                        if (questionsAnswers[target.name].length === 1) {
                            const copy = { ...totalRequiredFieldsCompleted };
                            delete copy[target.name];
                            setTotalRequiredFieldsCompleted(copy);
                        }
                    } else {
                        setTotalRequiredFieldsCompleted({
                            ...totalRequiredFieldsCompleted,
                            [target.name]: 0,
                        });
                    }
                }
            }
        }
        if (target.name.indexOf("question") === -1) {
            if (target.required) {
                if (value === "") {
                    const copy = { ...totalRequiredFieldsCompleted };
                    delete copy[target.name];
                    setTotalRequiredFieldsCompleted(copy);
                } else {
                    setTotalRequiredFieldsCompleted({
                        ...totalRequiredFieldsCompleted,
                        [target.name]: 0,
                    });
                }
            }
        }
        if (target.name.indexOf("cpf") !== -1) {
            setQuestionsAnswers({
                ...questionsAnswers,
                [target.name]: cpfMask(value),
            });
        } else if (target.name.indexOf("phone") !== -1) {
            setQuestionsAnswers({
                ...questionsAnswers,
                [target.name]: phoneMask(value),
            });
        } else {
            if (questionDb) {
                if (questionDb.type_field === "MS") {
                    if (questionsAnswers[target.name]) {
                        if (
                            questionsAnswers[target.name] &&
                            questionsAnswers[target.name].indexOf(value) !== -1
                        ) {
                            const indexExistItem =
                                questionsAnswers[target.name].indexOf(value);
                            const items = [...questionsAnswers[target.name]];
                            items.splice(indexExistItem, 1);
                            if (items.length === 0) {
                                const copy = { ...questionsAnswers };
                                delete copy[target.name];
                                setQuestionsAnswers(copy);
                            } else {
                                setQuestionsAnswers({
                                    ...questionsAnswers,
                                    [target.name]: items,
                                });
                            }
                        } else {
                            setQuestionsAnswers({
                                ...questionsAnswers,
                                [target.name]: [
                                    ...questionsAnswers[target.name],
                                    value,
                                ],
                            });
                        }
                    } else {
                        setQuestionsAnswers({
                            ...questionsAnswers,
                            [target.name]: [value],
                        });
                    }

                    return;
                }
            }
            setQuestionsAnswers({
                ...questionsAnswers,
                [target.name]: value,
            });
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoaderSubmit(true);
        if (questionsAnswers["client_cpf"] !== "") {
            if (!validarCPF(questionsAnswers["client_cpf"])) {
                setMessageErrorTitle("CPF inválido!");
                setMessageErrorDescription(
                    "O CPF informado é inválido. Por favor, verifique o CPF e tente novamente. Se o problema persistir, entre em contato com o suporte!"
                );
                toast.show();
                setLoaderSubmit(false);
                return;
            }
        }
        let dataToBeSentToServer = {
            questions: [],
        };
        const userAnswerCreatedId = await db.userAnswers.add({
            surveyId: survey.id,
            name: questionsAnswers.client_name,
            email: questionsAnswers.client_email,
            cpf: questionsAnswers.client_cpf,
            phone: questionsAnswers.client_phone,
            sentToServer: false,
        });
        dataToBeSentToServer["survey_id"] = survey.id;
        dataToBeSentToServer["client_name"] = questionsAnswers.client_name;
        dataToBeSentToServer["client_cpf"] = questionsAnswers.client_cpf;
        dataToBeSentToServer["client_email"] = questionsAnswers.client_email;
        dataToBeSentToServer["client_phone"] = questionsAnswers.client_phone;
        for (const questionIndex in questionsAnswers) {
            if (Object.hasOwnProperty.call(questionsAnswers, questionIndex)) {
                const questionValue = questionsAnswers[questionIndex];
                if (questionIndex.indexOf("question") === -1) {
                    continue;
                }
                const questionId = Number(
                    questionIndex.split("-")[
                        questionIndex.split("-").length - 1
                    ]
                );
                await db.answers.add({
                    userAnswerId: userAnswerCreatedId,
                    questionId: questionId,
                    value: questionValue,
                });
                dataToBeSentToServer.questions.push({
                    question_id: questionId,
                    value: questionValue,
                });
            }
        }
        try {
            const token = getToken();
            await fetch(`${URL_API}/pesquisas/criar/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Token ${token}`,
                },
                body: JSON.stringify(dataToBeSentToServer),
            });
            await db.userAnswers.update(userAnswerCreatedId, {
                sentToServer: true,
            });
        } catch (error) {
            setLoaderSubmit(false);
            console.error(error);
        }
        collapseSuccess.show();
        setLoaderSubmit(false);
    };

    return (
        <>
            <Preloader />
            <MenuMain />
            <FooterMenu />
            <PWA />

            <div
                id="notification-bar-choice-required"
                className="notification-bar glass-effect detached rounded-s shadow-l"
                data-bs-delay="15000"
            >
                <div className="toast-body px-3 py-3">
                    <div className="d-flex">
                        <div className="align-self-center">
                            <span className="icon icon-xxs rounded-xs bg-fade-red scale-box">
                                <i className="bi bi-exclamation-triangle color-red-dark font-16"></i>
                            </span>
                        </div>
                        <div className="align-self-center">
                            <h5 className="font-16 ps-2 ms-1 mb-0">
                                {messageErrorTitle}
                            </h5>
                        </div>
                    </div>
                    <p className="font-12 pt-2 mb-3">
                        {messageErrorDescription}
                    </p>
                    <a
                        onClick={() => toast.hide()}
                        className="btn btn-s text-uppercase rounded-xs font-11 font-700 btn-full btn-border border-fade-red color-red-dark"
                        aria-label="Close"
                    >
                        Fechar
                    </a>
                </div>
            </div>

            <div
                className="offcanvas offcanvas-modal rounded-m offcanvas-detached bg-theme"
                style={{
                    width: "340px",
                }}
                id="menu-success"
            >
                <div className="gradient-green px-3 py-3">
                    <div className="d-flex mt-1">
                        <div
                            className="align-self-center"
                            onClick={() => {
                                collapseSuccess.hide();
                            }}
                        >
                            <i className="bi bi-check-circle-fill font-22 pe-2 scale-box color-white"></i>
                        </div>
                        <div className="align-self-center">
                            <h1 className="font-700 color-white mb-0">
                                Sucesso
                            </h1>
                        </div>
                    </div>
                    <p className="color-white opacity-90 pt-2">
                        A pesquisa foi salva com sucesso! Em instantes será
                        sincronizada com o painel online, enquanto isso, você
                        pode usar o aplicativo normalmente!
                    </p>
                    <a
                        href="#"
                        className="default-link btn btn-full btn-s bg-white color-black"
                        onClick={() => {
                            collapseSuccess.hide();
                            history.push("/");
                        }}
                    >
                        Ok!
                    </a>
                </div>
            </div>

            <div className="page-content header-clear-medium">
                {loader ? (
                    <div className="preloader-l text-center">
                        <div
                            className="spinner-border color-blue-dark"
                            style={{
                                borderWidth: "7px",
                            }}
                            role="status"
                        ></div>
                    </div>
                ) : (
                    <>
                        <div className="card card-style">
                            <div className="content">
                                <h6 className="font-700 mb-n1 color-highlight">
                                    PESQUISA
                                </h6>
                                <h3 className="pb-2">{survey.name}</h3>
                                <p className="mb-0 mt-n1">
                                    {survey.description}
                                </p>
                                <small className="text-warning">
                                    * (obrigatório)
                                </small>
                            </div>
                        </div>
                        <form onSubmit={(event) => handleSubmit(event)}>
                            {questions.map((question, questionIndex) => (
                                <div
                                    className="card card-style"
                                    key={questionIndex}
                                >
                                    <div className="content">
                                        <small>{question.help_text}</small>
                                        <h2>
                                            {question.label}{" "}
                                            {question.required ? (
                                                <small className="text-warning">
                                                    *
                                                </small>
                                            ) : null}
                                        </h2>
                                        <br />
                                        {renderQuestionChoices(question)}
                                    </div>
                                </div>
                            ))}
                            <div className="card card-style">
                                <div className="content">
                                    <div className="form-custom form-label form-icon mb-3">
                                        <i className="bi bi-person-circle font-14"></i>
                                        <input
                                            type="text"
                                            className="form-control rounded-xs"
                                            id="client_name"
                                            placeholder="João Silva"
                                            name="client_name"
                                            onChange={handleInputChange}
                                        />
                                        <label
                                            htmlFor="client_name"
                                            className="color-theme form-label-always-active font-10 opacity-50"
                                        >
                                            Nome
                                        </label>
                                    </div>
                                    <div className="form-custom form-label form-icon mb-3">
                                        <i className="bi bi-123 font-16"></i>
                                        <input
                                            type="text"
                                            className="form-control rounded-xs"
                                            id="client_cpf"
                                            placeholder="100.200.300-45"
                                            name="client_cpf"
                                            onChange={(event) =>
                                                handleInputChange(event)
                                            }
                                            value={
                                                questionsAnswers["client_cpf"]
                                            }
                                        />
                                        <label
                                            htmlFor="client_cpf"
                                            className="color-theme form-label-always-active font-10 opacity-50 form-label-active"
                                        >
                                            CPF
                                        </label>
                                    </div>
                                    <div className="form-custom form-label form-icon mb-3">
                                        <i className="bi bi-at font-16"></i>
                                        <input
                                            type="text"
                                            className="form-control rounded-xs"
                                            id="client_email"
                                            placeholder="joao@gmail.com"
                                            name="client_email"
                                            onChange={handleInputChange}
                                        />
                                        <label
                                            htmlFor="client_email"
                                            className="color-theme form-label-always-active font-10 opacity-50 form-label-active"
                                        >
                                            E-mail
                                        </label>
                                    </div>
                                    <div className="form-custom form-label form-icon mb-3">
                                        <i className="bi bi-telephone font-16"></i>
                                        <input
                                            type="text"
                                            className="form-control rounded-xs"
                                            id="client_phone"
                                            placeholder="(44) 99999-5329"
                                            name="client_phone"
                                            onChange={handleInputChange}
                                            value={
                                                questionsAnswers["client_phone"]
                                            }
                                        />
                                        <label
                                            htmlFor="client_phone"
                                            className="color-theme form-label-always-active font-10 opacity-50 form-label-active"
                                        >
                                            Telefone
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="card card-style">
                                <div className="content">
                                    {loaderSubmit ? (
                                        <div className="d-flex justify-content-center">
                                            <div
                                                className="spinner-border color-red-dark"
                                                role="status"
                                            ></div>
                                        </div>
                                    ) : (
                                        <button
                                            className="btn btn-margins gradient-green shadow-bg shadow-bg-s rounded-s btn-m text-uppercase font-900"
                                            type="submit"
                                            style={{
                                                width: "100%",
                                            }}
                                            disabled={
                                                Object.keys(
                                                    totalRequiredFieldsCompleted
                                                ).length < totalRequiredFields
                                            }
                                        >
                                            Salvar Pesquisa!
                                        </button>
                                    )}
                                </div>
                            </div>
                        </form>
                    </>
                )}
            </div>
        </>
    );
};

export default Survey;
