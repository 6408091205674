import { useState, useEffect } from "react";
import { Offcanvas } from "bootstrap";

export const PWA = () => {
    const [iOSWindow, setIOSWindow] = useState(null);
    const [AndroidPWA, setAndroidPWA] = useState(null);
    const pwaRemind = 1;
    const pwaName = "Pesquisa Brasil Gazin";

    const isMobile = {
        Android: function () {
            return navigator.userAgent.match(/Android/i);
        },
        iOS: function () {
            return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        },
        any: function () {
            return isMobile.Android() || isMobile.iOS();
        },
    };

    useEffect(() => {
        const iOSWindowsParam = new Offcanvas(
            document.getElementById("menu-install-pwa-ios")
        );
        setIOSWindow(iOSWindowsParam);
        const AndroidPWAParam = new Offcanvas(
            document.getElementById("menu-install-pwa-android")
        );
        setAndroidPWA(AndroidPWAParam);
        if ("serviceWorker" in navigator) {
            // window.addEventListener("load", function () {
            //     navigator.serviceWorker
            //         .register(pwaLocation, { scope: pwaScope })
            //         .then(function (registration) {
            //             registration.update();
            //         });
            // });
        }
        //Setting Timeout Before Prompt Shows Again if Dismissed
        var hours = pwaRemind * 24; // Reset when storage is more than 24hours
        var now = Date.now();
        var setupTime = localStorage.getItem(pwaName + "-PWA-Timeout-Value");
        if (setupTime == null) {
            localStorage.setItem(pwaName + "-PWA-Timeout-Value", now);
        } else if (now - setupTime > hours * 60 * 60 * 1000) {
            localStorage.removeItem(pwaName + "-PWA-Prompt");
            localStorage.setItem(pwaName + "-PWA-Timeout-Value", now);
        }

        const pwaClose = document.querySelectorAll(".pwa-dismiss");
        pwaClose.forEach((el) =>
            el.addEventListener("click", (e) => {
                const pwaWindows = document.querySelectorAll(
                    "#menu-install-pwa-android, #menu-install-pwa-ios"
                );
                for (let i = 0; i < pwaWindows.length; i++) {
                    pwaWindows[i].classList.remove("menu-active");
                }
                localStorage.setItem(pwaName + "-PWA-Timeout-Value", now);
                localStorage.setItem(
                    pwaName + "-PWA-Prompt",
                    "install-rejected"
                );
                console.log(
                    "PWA Install Rejected. Will Show Again in " +
                        pwaRemind +
                        " Days"
                );
            })
        );

        //Trigger Install Prompt for Android
        const pwaWindows = document.querySelectorAll(
            "#menu-install-pwa-android, #menu-install-pwa-ios"
        );
        if (pwaWindows.length) {
            if (isMobile.Android()) {
                if (
                    localStorage.getItem(pwaName + "-PWA-Prompt") !=
                    "install-rejected"
                ) {
                    function showInstallPrompt() {
                        setTimeout(function () {
                            if (
                                !window.matchMedia("(display-mode: fullscreen)")
                                    .matches
                            ) {
                                console.log(
                                    "Triggering PWA Window for Android"
                                );
                                AndroidPWAParam.show();
                            }
                        }, 3500);
                    }
                    var deferredPrompt;
                    window.addEventListener("beforeinstallprompt", (e) => {
                        e.preventDefault();
                        deferredPrompt = e;
                        showInstallPrompt();
                    });
                }
                const pwaInstall = document.querySelectorAll(".pwa-install");
                pwaInstall.forEach((el) =>
                    el.addEventListener("click", (e) => {
                        deferredPrompt.prompt();
                        deferredPrompt.userChoice.then((choiceResult) => {
                            if (choiceResult.outcome === "accepted") {
                                console.log("Added");
                            } else {
                                localStorage.setItem(
                                    pwaName + "-PWA-Timeout-Value",
                                    now
                                );
                                localStorage.setItem(
                                    pwaName + "-PWA-Prompt",
                                    "install-rejected"
                                );
                                setTimeout(function () {
                                    if (
                                        !window.matchMedia(
                                            "(display-mode: fullscreen)"
                                        ).matches
                                    ) {
                                        AndroidPWAParam.show();
                                    }
                                }, 50);
                            }
                            deferredPrompt = null;
                        });
                    })
                );
                window.addEventListener("appinstalled", (evt) => {
                    AndroidPWAParam.hide();
                });
            }
            //Trigger Install Guide iOS
            if (isMobile.iOS()) {
                if (
                    localStorage.getItem(pwaName + "-PWA-Prompt") !=
                    "install-rejected"
                ) {
                    setTimeout(function () {
                        if (
                            !window.matchMedia("(display-mode: fullscreen)")
                                .matches
                        ) {
                            console.log("Triggering PWA Window for iOS");
                            iOSWindowsParam.show();
                        }
                    }, 3500);
                }
            }
        }
    }, []);

    return (
        <>
            <div
                className="offcanvas offcanvas-bottom rounded-m offcanvas-detached"
                id="menu-install-pwa-ios"
            >
                <div className="content">
                    <img
                        src="/apple-touch-icon.png"
                        alt="img"
                        width="80"
                        className="rounded-l mx-auto my-4"
                    />
                    <h1 className="text-center font-800 font-20">
                        Adicionar Pesquisa Brasil Gazin à tela inicial
                    </h1>
                    <p className="boxed-text-xl">
                        Instale o Pesquisa Brasil Gazin em sua tela inicial e
                        acesse-o como um aplicativo normal. Abra o menu Safari e
                        toque em "Adicionar a Tela inicial".
                    </p>
                    <a
                        href="#"
                        className="pwa-dismiss close-menu gradient-blue shadow-bg shadow-bg-s btn btn-s btn-full text-uppercase font-700  mt-n2"
                        data-bs-dismiss="offcanvas"
                    >
                        Talvez mais tarde
                    </a>
                </div>
            </div>

            <div
                className="offcanvas offcanvas-bottom rounded-m offcanvas-detached"
                id="menu-install-pwa-android"
            >
                <div className="content">
                    <img
                        src="/android-chrome-192x192.png"
                        alt="img"
                        width="80"
                        className="rounded-m mx-auto my-4"
                    />
                    <h1 className="text-center font-700 font-20">
                        Instalar Pesquisa Brasil Gazin
                    </h1>
                    <p className="boxed-text-l">
                        Instale o Pesquisa Brasil Gazin em sua tela inicial para
                        desfrutar de uma experiência única e experiência nativa.
                    </p>
                    <a
                        href="#"
                        className="pwa-install btn btn-m rounded-s text-uppercase font-900 gradient-highlight shadow-bg shadow-bg-s btn-full"
                    >
                        Adicionar à tela inicial
                    </a>
                    <br />
                    <a
                        href="#"
                        data-bs-dismiss="offcanvas"
                        className="pwa-dismiss close-menu color-theme text-uppercase font-900 opacity-50 font-11 text-center d-block mt-n1"
                    >
                        Talvez mais tarde
                    </a>
                </div>
            </div>
        </>
    );
};
