import axios from "axios";
import { getToken, getTokenCRMv3 } from "./auth";

export const URL_API = "https://pesquisabrasil.gazin.app.br/api";

const api = axios.create({
    baseURL: URL_API,
});

api.interceptors.request.use(async (config) => {
    const token = getToken();
    if (token) {
        config.headers["authorization"] = `Token ${token}`;
    }
    return config;
});

export default api;
