import { useHistory, Link } from "react-router-dom";
import { logout } from "../../services/auth";

const FooterMenu = (props) => {
    const history = useHistory();

    return (
        <div id="footer-bar" className="footer-bar footer-bar-detached">
            <a
                href=""
                onClick={(event) => {
                    event.preventDefault();
                    logout();
                    history.push("/login");
                }}
            >
                <i className="bi bi-arrow-left font-15"></i>
                <span>Sair</span>
            </a>
            <Link to="/">
                <i className="bi bi-star-fill font-17"></i>
                <span>Pesquisas</span>
            </Link>
            <Link to="/" className="active-nav">
                <i className="bi bi-house-fill font-16"></i>
                <span>Home</span>
            </Link>
            <Link to="/">
                <i className="bi bi-bell font-16"></i>
                <span>Notificações</span>
            </Link>
            <a href="#" data-bs-toggle="offcanvas" data-bs-target="#menu-main">
                <i className="bi bi-list"></i>
                <span>Menu</span>
            </a>
        </div>
    );
};

export default FooterMenu;
