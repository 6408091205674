import { useEffect } from "react";

const Preloader = () => {
    useEffect(() => {
        const timeout = setTimeout(() => {
            var preloader = document.getElementById("preloader");
            if (preloader) {
                preloader.classList.add("preloader-hide");
            }
        }, 150);
        return () => {
            clearTimeout(timeout);
        };
    }, []);

    return (
        <div id="preloader">
            <div className="spinner-border color-highlight" role="status"></div>
        </div>
    );
};

export default Preloader;
