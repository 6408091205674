// import "bootstrap/dist/css/bootstrap.css";
// import "bootstrap/dist/js/bootstrap.bundle";

import React, { useState } from "react";

import Routes from "./routes";

function App() {
    return <Routes />;
}

export default App;
