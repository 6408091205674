// Configs

export const TOKEN_KEY = "2De7drPV3pp5OqfXLDtxwvER1w9RcStsRna5zuhv";

// Login functions

export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const login = (token) => {
    localStorage.setItem(TOKEN_KEY, token);
};
export const logout = () => {
    localStorage.removeItem(TOKEN_KEY);
};

// User functions

export const getUserName = () =>
    JSON.parse(localStorage.getItem("user_data")).name;
