import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FooterMenu from "../../components/FooterMenu";
import { MenuMain } from "../../components/MenuMain";
import Preloader from "../../components/Preloader";

import { PWA } from "../../components/PWA";
import api from "../../services/api";

import db from "../../services/db";

const Dashboard = () => {
    const [surveys, setSurveys] = useState([]);

    useEffect(() => {
        const load = async () => {
            try {
                const surveysResponse = await api.get("/pesquisas/");
                await db.surveys.where({ active: 1 }).modify({ active: 0 });
                for (const survey of surveysResponse.data) {
                    await db.surveys.put({
                        id: survey.id,
                        name: survey.name,
                        description: survey.description,
                        active: survey.active ? 1 : 0,
                        background_photo: survey.background_photo,
                    });
                    for (const question of survey.questions) {
                        await db.questions.put({
                            id: question.id,
                            surveyId: survey.id,
                            label: question.label,
                            type_field: question.type_field,
                            choices: question.choices,
                            help_text: question.help_text,
                            required: question.required,
                            key: question.key,
                            ordering: question.ordering,
                        });
                    }
                }
            } catch (error) {
                console.error(error);
            }
            const surveysDb = await db.surveys
                .where("active")
                .equals(1)
                .toArray();
            setSurveys(surveysDb);
        };
        load();
    }, []);

    return (
        <>
            <Preloader />
            <MenuMain />
            <FooterMenu />
            <PWA />

            <div className="page-content header-clear-medium">
                <div className="card card-style">
                    <div className="content">
                        <h6 className="font-700 mb-n1 color-highlight">
                            Toque em suas pesquisas
                        </h6>
                        <h3 className="pb-2">Pesquisas</h3>
                        <p className="mb-0 mt-n1">
                            Veja todas as pesquisas disponíveis e utilize-as
                            para recolher dados importantes
                        </p>
                    </div>
                </div>

                {surveys.map((survey, surveyIndex) => (
                    <Link
                        to={`/pesquisa/${survey.id}`}
                        className="card card-style"
                        key={surveyIndex}
                    >
                        <div
                            className="card card-style m-0 bg-11 rounded-0"
                            style={{
                                height: "230px",
                                backgroundImage: `url('${survey.background_photo}')`,
                            }}
                        >
                            <div className="card-bottom p-2 mx-2">
                                <div className="d-flex">
                                    <div>
                                        <h6 className="font-700 mb-n1 opacity-50 color-white">
                                            Pesquisa
                                        </h6>
                                        <h2 className="color-white">
                                            {survey.name}
                                        </h2>
                                    </div>
                                    <div className="ms-auto align-self-center">
                                        <span className="btn btn-xxs rounded-s font-700 text-uppercase color-black bg-white float-end">
                                            Fazer agora
                                        </span>
                                    </div>
                                </div>
                                <p className="mb-2 mt-2 font-13 opacity-70 color-white">
                                    {survey.description}
                                </p>
                            </div>
                            <div className="card-overlay bg-gradient-fade rounded-0"></div>
                            <div className="card-overlay bg-black opacity-30 rounded-0"></div>
                        </div>
                    </Link>
                ))}
            </div>
        </>
    );
};

export default Dashboard;
